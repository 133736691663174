import React, { useEffect, useState } from "react";
import Sale01 from "../components/sale/Sale01";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import img from "../assets/images/avt/dude.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updateUserProfile,
  updateUserPassword,
} from "../redux/user/userActions";
import { getAllCountries } from "../redux/countryCode/countryCodeActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GetCoinImg } from "../assets/coinImg/getCoinImg";
import { getUserTransactions } from "../redux/transaction/transactionActions";
import { useTranslation } from "react-i18next";

UserProfile.propTypes = {};

function UserProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [profile, setProfile] = useState({
    username: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    fullName: "",
  });
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [viewPass, setViewPass] = useState(0);
  const [viewCPass, setViewCPass] = useState(0);

  const user = useSelector((state) => state.user?.user);
  const userId = useSelector((state) => state.user?.user?._id);
  const countries = useSelector((state) => state.countryCode.countries);
  const transactions = useSelector((state) => state.transaction?.transactions);
  // const totalUSD = useSelector((state) => state.transaction?.totalUSD);
  // console.log(profile);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
      dispatch(getUserTransactions(userId));
      //   dispatch(getUserTransactionTotal(userId));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    const year = d.getFullYear();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    setProfile({
      username: user.username,
      email: user.email,
      phone: user.phone,
      selectedCountry: user.selectedCountry,
      dateOfBirth: formatDate(user.dateOfBirth) || "",
      fullName: user.fullName || "",
    });
  }, [user]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "dateOfBirth") {
      // Removing all non-numeric characters first
      let onlyNums = value.replace(/[^\d]/g, "");

      // Automatically adding slashes between DD/MM/YYYY
      if (onlyNums.length > 4) {
        formattedValue = `${onlyNums.slice(0, 2)}/${onlyNums.slice(
          2,
          4
        )}/${onlyNums.slice(4)}`;
      } else if (onlyNums.length > 2) {
        formattedValue = `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
      } else {
        formattedValue = onlyNums;
      }
    }

    setProfile((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile(userId, profile)).then(() => {
      dispatch(getUser(userId));
    });
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  // Modify handlePasswordSubmit handler to send oldPassword too
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password.newPassword !== password.confirmPassword) {
      setErrors("Passwords do not match!");
    } else {
      dispatch(
        updateUserPassword(userId, {
          oldPassword,
          newPassword: password.newPassword,
        })
      );
    }
  };

  const handleDialCodeChange = (e) => {
    setProfile({
      ...profile,
      selectedCountry: {
        ...profile.selectedCountry,
        dial_code: e.target.value,
      },
    });
  };

  const handleCountryNameChange = (e) => {
    setProfile({
      ...profile,
      selectedCountry: { ...profile.selectedCountry, name: e.target.value },
    });
  };

  const [dataCoinTab] = useState([
    {
      id: "dataCoinTab1",
      icon: "fa-user",
    },
    {
      id: "dataCoinTab2",
      icon: "fa-share-nodes",
    },
    {
      id: "dataCoinTab3",
      icon: "fa-retweet",
    },
    {
      id: "dataCoinTab4",
      icon: "fa-lock",
    },
    // {
    //     id: 4,
    //     title: '2FA',
    //     icon: 'fa-barcode'
    // },
  ]);
  return (
    <div>
      <section className="user-profile flat-tabs">
        <div className="container">
          <div className="row">
            <Tabs>
              <TabList>
                <div className="user-info center">
                  <div className="avt">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="imgInp"
                      required
                    />
                    <img id="blah" src={img} alt="no file" />
                  </div>
                  <h6 className="name">{user.username}</h6>
                  <p>{user.email}</p>
                </div>
                {dataCoinTab.map((idx) => (
                  <Tab key={idx.id}>
                    <h6 className="fs-16">
                      <i className={`fa ${idx.icon}`}></i>
                      {t(`dataCoinTab.${idx.id}.title`)}
                    </h6>
                  </Tab>
                ))}
              </TabList>

              <TabPanel>
                <div className="content-inner profile">
                  <form onSubmit={handleProfileSubmit}>
                    <h4>{t("userProfile")}</h4>
                    <h6>{t("info")}</h6>

                    <div className="form-group d-flex s1">
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        value={profile.username}
                        onChange={handleProfileChange}
                      />
                      <input
                        type="text"
                        className="form-control"
                        name="fullName"
                        placeholder={t("fullName")}
                        value={profile.fullName}
                        onChange={handleProfileChange}
                      />
                    </div>
                    <div className="form-group d-flex">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="email"
                        value={profile.email}
                        onChange={handleProfileChange}
                      />
                      <div className="sl">
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={profile?.selectedCountry?.dial_code}
                          onChange={handleDialCodeChange}>
                          {countries.map((country) => (
                            <option
                              key={country.dial_code}
                              value={country.dial_code}>
                              {country.dial_code}
                            </option>
                          ))}
                        </select>
                        <input
                          type="phone"
                          className="form-control"
                          name="phone"
                          value={profile.phone}
                          onChange={handleProfileChange}
                        />
                      </div>
                    </div>

                    <div className="form-group d-flex">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect2"
                        value={profile?.selectedCountry?.name}
                        onChange={handleCountryNameChange}>
                        {countries.map((country) => (
                          <option key={country.name} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      <div className="sl">
                        {/* <select
                                                    className="form-control gt"
                                                    id="exampleFormControlSelect3"
                                                >
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select> */}
                        <input
                          className="form-control fc-datepicker"
                          placeholder="DD/MM/YYYY"
                          name="dateOfBirth"
                          value={profile.dateOfBirth || ""}
                          onChange={handleProfileChange}
                          type="text"
                        />
                      </div>
                    </div>

                    {/* <h6 className="two">Features</h6>
                                        <div className="bt d-flex">
                                            <div className="left">
                                                <h6>level 1</h6>
                                                <ul>
                                                    <li>
                                                        <p>Deposit assets</p>
                                                        <input
                                                            type="checkbox"
                                                            className="check-box__switcher"
                                                            checked
                                                        />
                                                    </li>
                                                    <li>
                                                        <p>Withdraw assets</p>
                                                        <p className="text">Enabled $1,000,000/day</p>
                                                    </li>
                                                    <li>
                                                        <p>Card purchases</p>
                                                        <input type="checkbox" className="check-box__switcher" />
                                                    </li>
                                                    <li>
                                                        <p>Bank deposit</p>
                                                        <input type="checkbox" className="check-box__switcher" />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="right">
                                                <h6>level 2</h6>
                                                <ul>
                                                    <li>
                                                        <p>Fiat and Spot wallet</p>
                                                        <input
                                                            type="checkbox"
                                                            className="check-box__switcher"
                                                            checked
                                                        />
                                                    </li>
                                                    <li>
                                                        <p>Margin wallet</p>
                                                        <p className="text">Enabled 100x Leverage</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}

                    <button type="submit" className="btn-action">
                      {t("profileUpdate")}
                    </button>
                  </form>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="content-inner referrals">
                  {/* <h6>Total rewards</h6>
                                    <h4>$1,056.00 <span>USD</span></h4>
                                    <p>
                                        You're earning 20% of the trading fees your referrals pay.
                                        Learn more
                                    </p> */}
                  <div className="main">
                    <h6>{t("comingSoon")}</h6>

                    {/* <div className="refe">
                                            <div>
                                                <p>Referral link</p>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value="https://accounts.Changebit.com/login"
                                                />
                                            </div>
                                            <div>
                                                <p>Referral code</p>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value="N84CRDKK"
                                                />
                                                <span className="btn-action">Copied</span>
                                            </div>
                                        </div> */}
                  </div>

                  {/* <Link to="/wallet" className="btn-action">My Wallet</Link> */}
                </div>
              </TabPanel>
              <TabPanel>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" style={{ color: "var(--onsurface)" }}>
                        #
                      </th>
                      <th scope="col" style={{ color: "var(--onsurface)" }}>
                        {t("sold")}
                      </th>
                      <th scope="col" style={{ color: "var(--onsurface)" }}>
                        {t("buy")}
                      </th>
                      <th scope="col" style={{ color: "var(--onsurface)" }}>
                        ID
                      </th>
                      <th scope="col" style={{ color: "var(--onsurface)" }}>
                        {t("time")}
                      </th>
                      {/* <th scope="col">Last 7 Days</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td>{transaction.id}</td>
                        <td style={{ color: "var(--onsurface)" }}>
                          {index + 1}
                        </td>
                        <td>
                          <Link to={`/buy-crypto-details/${transaction._id}`}>
                            {/* <img src={idx.icon} alt="Changebit" /> */}
                            <img
                              src={GetCoinImg(
                                transaction.fromCurrencyData[0]?.symbol
                              )}
                              alt=""
                              width="32"
                              height="32"
                            />
                            <span>{transaction.fromAmount}</span>
                            <span className="unit">
                              {transaction.fromCurrencyData[0]?.symbol}
                            </span>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/buy-crypto-details/${transaction._id}`}>
                            {/* <img src={idx.icon} alt="Changebit" /> */}
                            <img
                              src={GetCoinImg(
                                transaction.toCurrencyData[0]?.symbol
                              )}
                              alt=""
                              width="32"
                              height="32"
                            />
                            <span>{transaction.convertedAmount}</span>
                            <span className="unit">
                              {transaction.toCurrencyData[0]?.symbol}
                            </span>
                          </Link>
                        </td>
                        <td
                          className="boild"
                          style={{ color: "var(--onsurface)" }}>
                          <span>{transaction._id}</span>
                        </td>
                        <td
                          className="boild"
                          style={{ color: "var(--onsurface)" }}>
                          <span>{transaction.createdAt}</span>
                        </td>
                        {/* <td className={`${idx.class}`}>{idx.sale}</td>
                                                                    <td className="boild">{idx.cap}</td>
                                                                    <td><img src={idx.chart} alt="Changebit" /></td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TabPanel>
              {/* <TabPanel>
                                <div className="content-inner api">
                                    <h4>2FA <span className="color-success">Enabled</span></h4>
                                    <p>
                                        If you want to turn off 2FA, input your account password and
                                        the six-digit code provided by the Google Authenticator app
                                        below, then click <strong>"Disable 2FA"</strong>.
                                    </p>

                                    <div className="main">
                                        <h6>Disable 2FA</h6>
                                        <p>
                                            Enter your password and 2FA code to Disable the 2FA
                                            verification
                                        </p>

                                        <div className="refe">
                                            <div className="form-group">
                                                <p>Your Password</p>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    placeholder="Passworld"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <p>2FA Code</p>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="2FA code"
                                                />
                                            </div>
                                        </div>
                                        <Link to="#" className="btn-action">Disable 2FA verification</Link>
                                    </div>
                                </div>
                            </TabPanel> */}
              <TabPanel>
                <div className="content-inner profile change-pass">
                  <h4>{t("changePassword")}</h4>
                  <h6>{t("newPassword")}</h6>
                  <form onSubmit={handlePasswordSubmit}>
                    <div className="form-group">
                      <div>
                        <label>
                          {t("oldPassword")}
                          <span>*</span>:
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder={t("placeholderOldPassword")}
                          value={oldPassword}
                          onChange={handleOldPasswordChange}
                        />
                      </div>
                      {/* <div>
                                                <label>2FA Code<span>*</span>:</label>
                                                <input type="text" className="form-control" />
                                            </div> */}
                    </div>
                    <div className="form-group">
                      <div>
                        <label>
                          {t("newPassword")}
                          <span>
                            <span
                              style={{ marginLeft: "5px" }}
                              onClick={() => setViewPass(!viewPass)}>
                              <FontAwesomeIcon
                                style={{ color: "purple", fontSize: "20px" }}
                                icon={viewPass ? faEyeSlash : faEye}
                              />
                            </span>
                          </span>
                        </label>
                        <input
                          type={viewPass ? "text" : "password"}
                          className="form-control"
                          name="newPassword"
                          value={password.newPassword}
                          onChange={handlePasswordChange}
                          placeholder={t("placeholderNewPassword")}
                        />
                      </div>
                      <div>
                        <label>
                          {t("confirmPassword")}
                          <span>
                            <span
                              style={{ marginLeft: "5px" }}
                              onClick={() => setViewCPass(!viewCPass)}>
                              <FontAwesomeIcon
                                style={{ color: "purple", fontSize: "20px" }}
                                icon={viewCPass ? faEyeSlash : faEye}
                              />
                            </span>
                          </span>
                        </label>
                        <input
                          type={viewCPass ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          value={password.confirmPassword}
                          onChange={handlePasswordChange}
                          placeholder={t("confirmPassword")}
                        />
                      </div>
                    </div>
                    {errors ? (
                      <div
                        style={{ color: "#FE6E00" }}
                        className="alert alert-danger">
                        {" "}
                        {errors}{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    <button type="submit" className="btn-action">
                      {t("changePassword")}
                    </button>
                  </form>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>

      <Sale01 />
    </div>
  );
}

export default UserProfile;
