import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";

import "./styles.scss";
import icon1 from "../../assets/images/flags/united-states.png";
import icon2 from "../../assets/images/flags/south-korea.png";
import logo from "../../assets/images/logo/logo.png";
import logodark from "../../assets/images/logo/logo-dark.png";
import avt from "../../assets/images/avt/dude.png";
import DarkMode from "./DarkMode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

import { getUser } from "../../redux/user/userActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // const user = useSelector((state) => state.user?.user);
  const token = localStorage.getItem("uToken");

  const [activeIndex, setActiveIndex] = useState(null);
  const [menuActive, setMenuActive] = useState(null);
  const [scroll, setScroll] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    icon: icon1,
    name: t("English"),
    code: "en",
  });

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch]);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("uToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("uId");
    localStorage.clear();
    window.location = "/";
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const changeLanguage = (lng) => {
    let newLanguage = {};
    if (lng === "en") {
      newLanguage = { icon: icon1, name: t("English"), code: "en" };
    } else if (lng === "kr") {
      newLanguage = { icon: icon2, name: t("한국어"), code: "kr" };
    }
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(lng);
  };

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="header__body d-flex justify-content-between">
              <div className="header__left">
                <div className="logo">
                  <NavLink to="/" className="light">
                    <img
                      src={logo}
                      style={{ width: "172px", height: "52px" }}
                      alt="Changebit"
                    />
                  </NavLink>
                  <NavLink to="/" className="dark">
                    <img
                      src={logodark}
                      style={{ width: "172px", height: "52px" }}
                      alt="Changebit"
                    />
                  </NavLink>
                </div>
                <div className="left__main">
                  <nav
                    id="main-nav"
                    className={`main-nav ${menuActive ? "active" : ""}`}>
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, idx) => (
                        <li
                          key={idx}
                          onClick={() => handleDropdown(idx)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === idx ? "active" : ""}`}>
                          <Link to={data.links}>
                            {t(`menu.${data.name.toLowerCase()}`)}
                          </Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                  <NavLink to={submenu.links}>
                                    {t(
                                      `menu.sub.${submenu.sub
                                        .toLowerCase()
                                        .replace(" ", "_")}`
                                    )}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="header__right">
                <a
                  href="https://t.me/MySupportChangeBit"
                  target="_blank"
                  rel="noopener noreferrer">
                  <FontAwesomeIcon
                    className="icon-tg"
                    icon={faTelegram}
                    color="var(--primary)"
                  />
                </a>

                <Dropdown>
                  <Dropdown.Toggle>
                    <img
                      src={selectedLanguage.icon}
                      alt=""
                      className="me-1"
                      height="12"
                    />
                    <span className="align-middle language-name">
                      {selectedLanguage.name}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => changeLanguage("en")}
                      href="#">
                      <Link
                        to="#"
                        className="dropdown-item notify-item language"
                        data-lang="en">
                        <img src={icon1} alt="" className="me-1" height="12" />
                        <span className="align-middle">{t("English")}</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage("kr")}
                      href="#">
                      <Link
                        to="#"
                        className="dropdown-item notify-item language"
                        data-lang="kr">
                        <img src={icon2} alt="" className="me-1" height="12" />
                        <span className="align-middle">한국어</span>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <DarkMode />

                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}>
                  <span></span>
                </div>
                {token ? (
                  <Dropdown className="user">
                    <Dropdown.Toggle>
                      <img src={avt} alt="Changebit" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <Link className="dropdown-item" to="/user-profile">
                          <i className="bx bx-user font-size-16 align-middle me-1"></i>
                          <span>{t("profile")}</span>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <Link className="dropdown-item" to="/buy-crypto-select">
                          <i className="bx bx-wallet font-size-16 align-middle me-1"></i>
                          <span>{t("exchange")}</span>
                        </Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">
                                                <Link className="dropdown-item d-block" to="#"
                                                ><i
                                                    className="bx bx-wrench font-size-16 align-middle me-1"
                                                ></i>
                                                    <span>Settings</span></Link>
                                            </Dropdown.Item> */}
                      <Dropdown.Item href="#">
                        <Link className="dropdown-item text-danger" to="/login">
                          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                          <span onClick={(e) => handleLogout(e)} s>
                            {t("logout")}
                          </span>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <div className="wallet">
                    <Link to="/login">
                      <i className="bx bx-user font-size-16 align-middle me-1 mobile-icon"></i>
                      <span className="login-text">{t("login")}</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
